exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apky-cestovny-prikaz-tsx": () => import("./../../../src/pages/apky/cestovny_prikaz.tsx" /* webpackChunkName: "component---src-pages-apky-cestovny-prikaz-tsx" */),
  "component---src-pages-apky-flexi-praca-teamee-tsx": () => import("./../../../src/pages/apky/flexi_praca_teamee.tsx" /* webpackChunkName: "component---src-pages-apky-flexi-praca-teamee-tsx" */),
  "component---src-pages-apky-profesional-remeselnik-tsx": () => import("./../../../src/pages/apky/profesional_remeselnik.tsx" /* webpackChunkName: "component---src-pages-apky-profesional-remeselnik-tsx" */),
  "component---src-pages-apky-sprava-pripadov-tsx": () => import("./../../../src/pages/apky/sprava_pripadov.tsx" /* webpackChunkName: "component---src-pages-apky-sprava-pripadov-tsx" */),
  "component---src-pages-apky-tsx": () => import("./../../../src/pages/apky.tsx" /* webpackChunkName: "component---src-pages-apky-tsx" */),
  "component---src-pages-apps-business-travel-log-tsx": () => import("./../../../src/pages/apps/business_travel_log.tsx" /* webpackChunkName: "component---src-pages-apps-business-travel-log-tsx" */),
  "component---src-pages-apps-case-worker-tsx": () => import("./../../../src/pages/apps/case_worker.tsx" /* webpackChunkName: "component---src-pages-apps-case-worker-tsx" */),
  "component---src-pages-apps-en-tsx": () => import("./../../../src/pages/apps.en.tsx" /* webpackChunkName: "component---src-pages-apps-en-tsx" */),
  "component---src-pages-apps-profi-project-tsx": () => import("./../../../src/pages/apps/profi_project.tsx" /* webpackChunkName: "component---src-pages-apps-profi-project-tsx" */),
  "component---src-pages-apps-sport-events-tsx": () => import("./../../../src/pages/apps/sport_events.tsx" /* webpackChunkName: "component---src-pages-apps-sport-events-tsx" */),
  "component---src-pages-apps-teamee-tsx": () => import("./../../../src/pages/apps/teamee.tsx" /* webpackChunkName: "component---src-pages-apps-teamee-tsx" */),
  "component---src-pages-blog-index-cs-tsx": () => import("./../../../src/pages/blog/index.cs.tsx" /* webpackChunkName: "component---src-pages-blog-index-cs-tsx" */),
  "component---src-pages-blog-index-en-tsx": () => import("./../../../src/pages/blog/index.en.tsx" /* webpackChunkName: "component---src-pages-blog-index-en-tsx" */),
  "component---src-pages-blog-index-sk-tsx": () => import("./../../../src/pages/blog/index.sk.tsx" /* webpackChunkName: "component---src-pages-blog-index-sk-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-sk-tsx": () => import("./../../../src/pages/contact.sk.tsx" /* webpackChunkName: "component---src-pages-contact-sk-tsx" */),
  "component---src-pages-features-en-tsx": () => import("./../../../src/pages/features.en.tsx" /* webpackChunkName: "component---src-pages-features-en-tsx" */),
  "component---src-pages-features-sk-tsx": () => import("./../../../src/pages/features.sk.tsx" /* webpackChunkName: "component---src-pages-features-sk-tsx" */),
  "component---src-pages-index-cs-tsx": () => import("./../../../src/pages/index.cs.tsx" /* webpackChunkName: "component---src-pages-index-cs-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-sk-tsx": () => import("./../../../src/pages/index.sk.tsx" /* webpackChunkName: "component---src-pages-index-sk-tsx" */),
  "component---src-pages-integrations-en-tsx": () => import("./../../../src/pages/integrations.en.tsx" /* webpackChunkName: "component---src-pages-integrations-en-tsx" */),
  "component---src-pages-integrations-sk-tsx": () => import("./../../../src/pages/integrations.sk.tsx" /* webpackChunkName: "component---src-pages-integrations-sk-tsx" */),
  "component---src-pages-new-en-tsx": () => import("./../../../src/pages/new.en.tsx" /* webpackChunkName: "component---src-pages-new-en-tsx" */),
  "component---src-pages-support-en-tsx": () => import("./../../../src/pages/support.en.tsx" /* webpackChunkName: "component---src-pages-support-en-tsx" */),
  "component---src-pages-support-sk-tsx": () => import("./../../../src/pages/support.sk.tsx" /* webpackChunkName: "component---src-pages-support-sk-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog_category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

